/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: Phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

h1,
h2 {
  font-weight: 700;
}

/* $btn-border-radius: 12px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 14px; */
:root {
  --bs-body-bg: #f8f9fa;
  --bs-body-bg-rgb: 248, 249, 250;
  --pc-heading-color: #1d2630;
  --pc-active-background: #f3f5f7;
  --pc-sidebar-background: transparent;
  --pc-sidebar-color: #5b6b79;
  --pc-sidebar-color-rgb: 91, 107, 121;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: #3e4853;
  --pc-sidebar-border: 1px dashed #bec8d0;
  --pc-sidebar-user-background: #f3f5f7;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #5b6b79;
  --pc-header-shadow: none;
  --pc-card-box-shadow: none;
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #5b6b79;
}

[data-pc-theme_contrast=true] {
  --bs-body-bg: #ffffff;
  --pc-sidebar-background: transparent;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: 1px 0 3px 0px #dbe0e5;
  --pc-sidebar-border: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

section {
  padding: 100px 0;
}

.title {
  margin-bottom: 50px;
}
.title h2 {
  font-weight: 600;
}
.title h5 {
  font-weight: 500;
}

.landing-page {
  overflow-x: hidden;
  background: var(--bs-body-bg);
}
@media (min-width: 1600px) {
  .landing-page .container {
    max-width: 1200px;
  }
}

.navbar {
  position: fixed;
  padding: 16px 0;
  width: 100%;
  z-index: 1099;
  top: 0;
  backdrop-filter: blur(7px);
  background-color: var(--pc-header-background);
}
.navbar.top-nav-collapse.default {
  box-shadow: none;
}
.navbar.default, .navbar.top-nav-collapse {
  box-shadow: 0 8px 6px -10px rgba(0, 0, 0, 0.5);
}
.navbar .nav-link {
  font-weight: 500;
}
.navbar .nav-link:active, .navbar .nav-link:hover, .navbar .nav-link:focus {
  color: var(--bs-primary);
}

header {
  overflow: hidden;
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: cover;
  flex-direction: column;
}
header .technology-block {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: var(--bs-white);
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
header .technology-block .list-inline-item {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  margin-right: 0;
}
header .technology-block .list-inline-item img {
  padding: 10px;
}
header .technology-block .list-inline-item:first-child {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
header h1 {
  font-size: 55px;
}
header .head-rating-block {
  position: relative;
}
header .head-rating-block:after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: auto;
  right: 0;
  width: 1px;
  background: var(--bs-border-color);
}
header .container {
  position: relative;
  z-index: 5;
}

.hero-text-gradient {
  --bg-size: 400%;
  --color-one: rgb(37, 161, 244);
  --color-two: rgb(249, 31, 169);
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0/var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 24s infinite linear;
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}
.app-link {
  padding: 30px;
  margin: 10px 0;
  display: block;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.app-link.active, .app-link:hover {
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

.img-app-moke {
  transform-origin: 0 0;
  transform: scale(1.5);
}

.workspace-card-block .card {
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  position: relative;
}
.workspace-card-block .card .avtar {
  background: rgba(204, 204, 204, 0.25);
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  margin-bottom: 24px;
}
.workspace-card-block .card .avtar svg {
  width: 30px;
  height: 30px;
  color: var(--bs-gray-500);
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
}
.workspace-card-block .active .card,
.workspace-card-block .card:hover {
  background: rgba(204, 204, 204, 0.1);
}
.workspace-card-block .active .card .avtar,
.workspace-card-block .card:hover .avtar {
  background: rgba(var(--bs-primary-rgb), 0.25);
}
.workspace-card-block .active .card .avtar svg,
.workspace-card-block .card:hover .avtar svg {
  color: var(--bs-primary);
}
.workspace-card-block .active .card::after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--bs-primary);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.workspace-slider-block {
  overflow: hidden;
  background-size: cover;
  padding: 35px 0;
}
.workspace-slider-block .img-ws-slider {
  margin: 30px 0;
  box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
}
.workspace-slider-block .owl-item:not(.active) .img-ws-slider {
  opacity: 0.5;
}

.support-team-block {
  overflow: hidden;
  background-size: cover;
}
.support-team-block .support-card {
  width: 420px;
  margin-bottom: 0;
}
.support-team-block .support-card p {
  white-space: pre-wrap;
}

.marquee-list {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: 20px;
}
.marquee-list li {
  margin: 0 10px;
  cursor: pointer;
}
.marquee-list li:hover {
  opacity: 1;
}

.marquee-text {
  position: relative;
  height: 150px;
  display: inline-flex;
}
.marquee-text .js-marquee {
  margin-right: 0 !important;
}

.img-suggest-moke {
  max-width: 1110px;
  float: right;
}

.client-block img {
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.client-block img:hover {
  filter: grayscale(0);
  opacity: 1;
}

.footer {
  padding: 100px 0 20px;
  background-size: cover;
}
.footer .footer-center {
  padding: 60px 0;
  margin: 60px 0 20px;
}
.footer .footer-link a {
  margin: 14px 0;
  display: block;
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  color: var(--bs-primary);
}
.footer .footer-link a:not(:hover) {
  color: var(--bs-body-color);
  opacity: 0.9;
}
.footer .footer-sos-link a {
  transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
  color: var(--bs-primary);
}
.footer .footer-sos-link a:not(:hover) {
  color: var(--bs-body-color);
  opacity: 0.9;
}

@media (max-width: 991.98px) {
  section {
    padding: 40px 0;
  }
}
@media (max-width: 767.98px) {
  .img-suggest-moke {
    max-width: 100%;
    float: none;
    margin-bottom: 30px;
  }
  .workspace-card-block .card .card-body {
    display: flex;
    align-items: flex-start;
  }
  .workspace-card-block .card .card-body .avtar {
    margin-right: 24px;
    flex-shrink: 0;
  }
  header {
    text-align: center;
    padding: 100px 0;
  }
  header h1 {
    font-size: 25px;
  }
  header .technology-block .list-inline-item:first-child {
    border-left: none;
  }
  header .technology-block .list-inline-item:last-child {
    border-right: none;
  }
  header .technology-block .list-inline-item img {
    padding: 8px;
    width: 55px;
  }
  .footer .footer-center {
    padding: 30px 0;
    margin: 30px 0 20px;
  }
}
[data-pc-theme=dark].landing-page .bg-white {
  background: #1b232d !important;
  --bs-white: lighten($dark-layout-color, 4%);
}
[data-pc-theme=dark].landing-page .bg-gray-100 {
  background: var(--bs-dark);
}
[data-pc-theme=dark].landing-page header {
  background: none !important;
}
[data-pc-theme=dark].landing-page header .technology-block {
  background: #131920;
}
[data-pc-theme=dark].landing-page .contact-hero {
  background-image: url("../images/landing/img-headerbg-dark.jpg") !important;
}

[data-pc-direction=rtl] header .technology-block .list-inline-item {
  border-right: none;
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  margin-left: 0;
}
[data-pc-direction=rtl] header .technology-block .list-inline-item:first-child {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}
[data-pc-direction=rtl] .img-app-moke {
  transform-origin: 100% 0;
  direction: ltr;
}
[data-pc-direction=rtl] .marquee-1,
[data-pc-direction=rtl] .marquee {
  direction: ltr;
  float: left;
}
[data-pc-direction=rtl] .marquee-1 .d-flex,
[data-pc-direction=rtl] .marquee .d-flex {
  flex-direction: row-reverse;
}